import { Icons } from './constants'

type IconProps = {
  iconName: Icons
  ariaHidden?: boolean
  ariaLabel?: string
  className?: string
  height?: number
  width?: number
  title?: string
  onClick?: () => void
}

const Icon = ({
  ariaHidden = true,
  ariaLabel,
  height = 32,
  iconName,
  width = 32,
  className = '',
  title,
  onClick,
}: IconProps): JSX.Element => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    role='presentation'
    className={`icon icon-${iconName} ${className}`}
    aria-label={ariaLabel}
    aria-hidden={ariaHidden}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    onClick={onClick}>
    <title>{title ?? `icon-${iconName}`}</title>
    <use xlinkHref={`/assets/icons/sprite/icons.svg#${iconName}`} />
  </svg>
)

export default Icon

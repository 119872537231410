'use client'

import {
  createContext,
  ReactElement,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react'
import { setCookie } from 'cookies-next'
import { StoreConfig } from '../../domain/storeConfig'
import {
  ShippingZone,
  getStoresByShippingZone,
  getShippingZoneByStore,
  getDefaultShippingZone,
  changeStoreUtils,
  DefaultStoreCode,
} from '../../domain/store'

const STORE_LOCAL_NAME = 'store'

export type ConfigContextType = {
  storeConfig: StoreConfig
  shippingZone: string
  changeStoreCode: (storeCode: string) => void
  changeShippingZone: (shippingZone: string) => void
  isEcommerce: () => boolean
  locale: string
  shippingZones: ShippingZone[]
  translations: any
  deviceType: string
}

const ConfigDefaultValues = {
  storeConfig: {} as StoreConfig,
  shippingZone: getDefaultShippingZone(),
  changeStoreCode: () => {},
  changeShippingZone: () => {},
  isEcommerce: () => false,
  locale: DefaultStoreCode,
  shippingZones: [],
  translations: {},
  deviceType: 'desktop',
}

export const ConfigContext = createContext<ConfigContextType>(ConfigDefaultValues)

const ConfigProvider = ({
  children,
  locale,
  storeConfig,
  shippingZones,
  translations,
}: {
  children: ReactElement
  locale: string
  storeConfig: StoreConfig
  shippingZones: ShippingZone[]
  translations: any
}) => {
  const [shippingZone, setShippingZone] = useState(
    getShippingZoneByStore(shippingZones, locale)?.code ?? getDefaultShippingZone(),
  )
  const [deviceType, setDeviceType] = useState<string>('desktop')

  useEffect(() => {
    setCookie('locale', locale)
  }, [locale])

  useEffect(() => {
    window.localStorage.setItem(STORE_LOCAL_NAME, JSON.stringify(storeConfig))
  }, [storeConfig, locale])

  useEffect(() => {
    function doSetDeviceType() {
      if (window.innerWidth < 600) {
        return setDeviceType('mobile')
      }
      if (window.innerWidth < 960) {
        return setDeviceType('tablet')
      }

      setDeviceType('desktop')
    }
    window.addEventListener('resize', () => {
      doSetDeviceType();
    })
    doSetDeviceType();
  }, [])

  const changeStoreCode = useCallback((domain: string) => (window.location.href = domain), [])

  const changeShippingZone = useCallback(
    (newShippingZone: string) => {
      setShippingZone(newShippingZone)
      const store = getStoresByShippingZone(shippingZones, newShippingZone)

      if (store && store.length > 0) {
        changeStoreUtils()
        const domain = store[0]?.domain ?? ''
        changeStoreCode(domain)
      }
    },
    [changeStoreCode],
  )

  const isEcommerce = useCallback(
    () => (storeConfig.ecommerce === 1 ? true : false),
    [shippingZone, locale],
  )
  const context = useMemo(
    () => ({
      storeConfig,
      shippingZone,
      changeStoreCode,
      changeShippingZone,
      isEcommerce,
      locale,
      shippingZones,
      translations,
      deviceType,
    }),
    [
      storeConfig,
      shippingZone,
      changeStoreCode,
      changeShippingZone,
      isEcommerce,
      shippingZones,
      translations,
      deviceType,
    ],
  )

  return <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
}

export type KeyTranslationOptions = {
  variables?: Record<string, number | string>
}

export const findTranslation =
  (dictionary: Record<string, string>) =>
  (key: string, options: KeyTranslationOptions = {}, allowTags: boolean = false, nullIfNotFound = false) => {
    const tagRegex = /<[^]*?>/gm
    const varRegex = /%[1-9]/gm

    let translation = dictionary[key]


    if (!translation) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        // console.warn(`key "${key}" has no translation`)
      }
      if (nullIfNotFound) {
        return null
      }

      translation = key
    }

    if (process.env.NODE_ENV === 'development' && allowTags) {
      console.log(`findTranslation for key "${key}": allowTags does nothing on client.`)
    }

    if (options.variables && Object.entries(options.variables).length) {
      translation.match(varRegex)?.forEach((hit, index) => {
        translation = translation!.replace(String(hit), String(options?.variables?.[index]))
      })
    }

    return translation.match(tagRegex)
      ? ((<span dangerouslySetInnerHTML={{ __html: translation }}></span>) as any)
      : translation
  }

export const useTranslation = () => {
  const { translations } = useContext(ConfigContext)

  return findTranslation(translations)
}

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

export default ConfigProvider

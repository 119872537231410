'use client'

import { useContext } from 'react'
import { ConfigContext } from '../../presentation/context/ConfigContext'
import { DefaultStoreCode } from '../../domain/store'

const useCurrentLocale = () => {
  const { locale } = useContext(ConfigContext)

  return locale || DefaultStoreCode
}

export default useCurrentLocale

export enum Icons {
  ARROW_SWITCH = 'arrow-switch',
  ARROW_TOP = 'arrow-top',
  BELL = 'bell',
  BIKE = 'bike',
  CAR = 'car',
  CART = 'cart',
  CLOCK = 'clock',
  CLOSE = 'close',
  DOWNLOAD = 'download',
  EYE_EMPTY = 'eye-empty',
  EYE_OFF = 'eye-off',
  FILTER_LIST = 'filter-list',
  HEART = 'heart',
  HELP_CIRCLE = 'help-circle',
  LOADING = 'loading',
  MENU = 'menu',
  MESSIKA_LOGO = 'messika-logo',
  MESSIKA_LOGO_MONOGRAMME_LARGE = 'messika-logo-monogramme-large',
  MESSIKA_LOGO_WHITE = 'messika-logo-white',
  NAV_ARROW_DOWN = 'nav-arrow-down',
  NAV_ARROW_RIGHT = 'nav-arrow-right',
  PAUSE = 'pause',
  PENCIL = 'pencil',
  PHONE = 'phone',
  PIN = 'pin',
  PLAY = 'play',
  SEARCH = 'search',
  SHOPPING_BAG = 'shopping-bag',
  SOUND_HIGH = 'sound-high',
  SOUND_OFF = 'sound-off',
  SUCCESS = 'success',
  TRAIN = 'train',
  USER = 'user',
  WALKING = 'walking',
  WISHLIST = 'wishlist',
  WISHLIST_FULL = 'wishlist-full',
} 
export const buildFullUrlMedia = (partialUrl: string) => {
  const publicSiteUrl = process.env.NEXT_PUBLIC_CLOUDFRONT_URL
  if (!publicSiteUrl) {
    return partialUrl
  }
  if (partialUrl.includes(publicSiteUrl)) {
    return partialUrl
  }
  // avoid double //, because it will process a redirection 308 and add a new hit
  if (publicSiteUrl.endsWith('/') && partialUrl.startsWith('/')) {
    return `${publicSiteUrl}${partialUrl.replace('/', '')}`
  }
  return `${publicSiteUrl}${partialUrl}`
}

export const handleError = (error: unknown, shouldThrowError: boolean = true, prefix?: string) => {
  let formattedError

  if (error instanceof Error) {
    formattedError = error.message
  } else {
    formattedError = String(error)
  }

  /* eslint-disable no-console */
  console.error(prefix ?? '', formattedError)

  if (shouldThrowError) {
    throw new Error(formattedError)
  }
}

export function stripHtml(text: string) {
  let open = false
  let plainText = ''
  for (let i = 0; i < text.length; i += 1) {
    const c = text[i]
    if (c === '<') {
      open = true
    } else if (c === '>') {
      open = false
    } else if (!open) {
      plainText += c
    }
  }
  return plainText
}


export function chunk<T>(arr: T[], size: number) {
  const chunks: T[][] = [];
  let currChunk: T[] = []
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i]
    currChunk.push(item)
    if (currChunk.length === size) {
      chunks.push(currChunk)
      currChunk = []
    }
  }
  if (currChunk.length > 0) {
    chunks.push(currChunk)
  }
  return chunks
}
